import React from "react";
import { Box, CssBaseline } from "@material-ui/core";
import theme from "../../common/theme/theme";
import BaselineSeo from "../BaselineSeo";
import "../../css/custom.css";
import Header from "../Header/Header";
import { ThemeProvider } from "@material-ui/core/styles";
import Footer from "../Footer";

export default function Layout(props) {
  const { sideMenuCollections, onQueryChange } = props;
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BaselineSeo />
        <CssBaseline />
        <Box>
          <Header
            sideMenuCollections={sideMenuCollections}
            onQueryChange={onQueryChange}
          />
        </Box>
        {props.children}
        <Footer></Footer>
      </ThemeProvider>
    </React.Fragment>
  );
}
